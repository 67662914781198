import {apiInstance} from './api';


export const get_item_stock_summary_report = (office_id) => {
  return apiInstance.get(`/report/item_stock/summary/?office_id=${office_id}`);
};

export const get_purchase_list_report = (page, per_page, sort_by, sort_order, output, party_id, from_date, to_date, purchase_id, bill_no) => {
  let url = `/report/purchase/list/?page=${page}&per_page=${per_page}&sort_by=${sort_by}&sort_order=${sort_order}&pagination=true`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (party_id !== undefined && party_id !== '') {
    url += `&party_id=${party_id}`;
  }

  if (from_date !== undefined && from_date !== '') {
    url += `&from_date=${from_date}`;
  }

  if (to_date !== undefined && to_date !== '') {
    url += `&to_date=${to_date}`;
  }

  if (purchase_id !== undefined && purchase_id !== '') {
    url += `&purchase_id=${purchase_id}`;
  }

  if (bill_no !== undefined && bill_no !== '') {
    url += `&bill_no=${bill_no}`;
  }

  if (output === 'excel') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};

export const get_customer_ledger = (output, report_type, office_id, jbl_office_id, category_id, customer_id, from_date, to_date) => {
  let url = `/report/customer_ledger/?report_type=${report_type}&from_date=${from_date}&to_date=${to_date}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (office_id !== undefined && office_id !== '') {
    url += `&office_id=${office_id}`;
  }

  if (jbl_office_id !== undefined && jbl_office_id !== '') {
    url += `&jbl_office_id=${jbl_office_id}`;
  }

  if (category_id !== undefined && category_id !== '') {
    url += `&category_id=${category_id}`;
  }

  if (customer_id !== undefined && customer_id !== '') {
    url += `&customer_id=${customer_id}`;
  }

  if (output === 'excel') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};

export const get_customer_ledger_balance = (output, report_type, office_id, jbl_office_id, category_id, customer_id, to_date, from_item_order) => {
  let url = `/report/customer_ledger_balance/?report_type=${report_type}&to_date=${to_date}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (office_id !== undefined && office_id !== '') {
    url += `&office_id=${office_id}`;
  }

  if (jbl_office_id !== undefined && jbl_office_id !== '') {
    url += `&jbl_office_id=${jbl_office_id}`;
  }

  if (category_id !== undefined && category_id !== '') {
    url += `&category_id=${category_id}`;
  }

  if (customer_id !== undefined && customer_id !== '') {
    url += `&customer_id=${customer_id}`;
  }

  if (from_item_order !== undefined && from_item_order !== '') {
    url += `&from_item_order=${from_item_order}`;
  }

  if (output === 'excel') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};

export const get_customer_incentive_expense_report = (output, report_type, office_id, jbl_office_id, category_id, customer_id, from_date, to_date) => {
  let url = `/report/customer_incentive_expense/?report_type=${report_type}&from_date=${from_date}&to_date=${to_date}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (office_id !== undefined && office_id !== '') {
    url += `&office_id=${office_id}`;
  }

  if (jbl_office_id !== undefined && jbl_office_id !== '') {
    url += `&jbl_office_id=${jbl_office_id}`;
  }

  if (category_id !== undefined && category_id !== '') {
    url += `&category_id=${category_id}`;
  }

  if (customer_id !== undefined && customer_id !== '') {
    url += `&customer_id=${customer_id}`;
  }

  if (output === 'excel') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};

export const get_jbl_office_ledger = (output, report_type, office_id, jbl_office_id, from_date, to_date) => {
  let url = `/report/jbl_office_ledger/?report_type=${report_type}&from_date=${from_date}&to_date=${to_date}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (office_id !== undefined && office_id !== '') {
    url += `&office_id=${office_id}`;
  }

  if (jbl_office_id !== undefined && jbl_office_id !== '') {
    url += `&jbl_office_id=${jbl_office_id}`;
  }

  if (output === 'excel') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};

export const get_sales_bill_register_report_form = () => {
  let url = `/report/sales_bill_register/?action=form`;

  return apiInstance.get(url);
};

export const get_sales_bill_register_report = (output, report_type, report_view, office_id, jbl_office_id, customer_id, item_id, category_id, from_date, to_date) => {
  let url = `/report/sales_bill_register/?report_type=${report_type}&report_view=${report_view}&from_date=${from_date}&to_date=${to_date}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (office_id !== undefined && office_id !== '') {
    url += `&office_id=${office_id}`;
  }

  if (jbl_office_id !== undefined && jbl_office_id !== '') {
    url += `&jbl_office_id=${jbl_office_id}`;
  }

  if (customer_id !== undefined && customer_id !== '') {
    url += `&customer_id=${customer_id}`;
  }

  if (item_id !== undefined && item_id !== '') {
    url += `&item_id=${item_id}`;
  }

  if (category_id !== undefined && category_id !== '') {
    url += `&category_id=${category_id}`;
  }

  if (output === 'excel') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};

export const get_sales_return_register_report = (output, report_type, report_view, office_id, jbl_office_id, customer_id, item_id, from_date, to_date) => {
  let url = `/report/sales_return_register/?report_type=${report_type}&report_view=${report_view}&from_date=${from_date}&to_date=${to_date}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (office_id !== undefined && office_id !== '') {
    url += `&office_id=${office_id}`;
  }

  if (jbl_office_id !== undefined && jbl_office_id !== '') {
    url += `&jbl_office_id=${jbl_office_id}`;
  }

  if (customer_id !== undefined && customer_id !== '') {
    url += `&customer_id=${customer_id}`;
  }

  if (item_id !== undefined && item_id !== '') {
    url += `&item_id=${item_id}`;
  }

  if (output === 'excel') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};

export const get_stock_register_report = (output, report_type, report_view, office_id, item_id, show_minus_only, include_child, from_date, to_date) => {
  let url = `/report/stock_register/?report_type=${report_type}&report_view=${report_view}&from_date=${from_date}&to_date=${to_date}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (office_id !== undefined && office_id !== '') {
    url += `&office_id=${office_id}`;
  }

  if (item_id !== undefined && item_id !== '') {
    url += `&item_id=${item_id}`;
  }

  if (show_minus_only !== undefined && show_minus_only !== '') {
    url += `&show_minus_only=${show_minus_only}`;
  }

  if (include_child !== undefined && include_child !== '') {
    url += `&include_child=${include_child}`;
  }

  if (output === 'excel') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};

export const get_account_ledger_detail = (output, office_id, account_id, from_date, to_date) => {
  let url = `/report/account/ledger_detail/?from_date=${from_date}&to_date=${to_date}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (office_id !== undefined && office_id !== '') {
    url += `&office_id=${office_id}`;
  }

  if (account_id !== undefined && account_id !== '') {
    url += `&account_id=${account_id}`;
  }

  if (output === 'excel' || output === 'pdf') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};

export const get_stock_transfer_report = (output, report_type, report_view, from_office_id, to_office_id, item_id, from_date, to_date) => {
  let url = `/report/stock_transfer/?report_type=${report_type}&report_view=${report_view}&from_date=${from_date}&to_date=${to_date}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (from_office_id !== undefined && from_office_id !== '') {
    url += `&from_office_id=${from_office_id}`;
  }

  if (to_office_id !== undefined && to_office_id !== '') {
    url += `&to_office_id=${to_office_id}`;
  }

  if (item_id !== undefined && item_id !== '') {
    url += `&item_id=${item_id}`;
  }

  if (output === 'excel') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};

export const get_account_voucher_report = (output, report_type, report_view, office_id, voucher_no, from_date, to_date) => {
  let url = `/report/account/voucher/?report_type=${report_type}&report_view=${report_view}&from_date=${from_date}&to_date=${to_date}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (office_id !== undefined && office_id !== '') {
    url += `&office_id=${office_id}`;
  }

  if (voucher_no !== undefined && voucher_no !== '') {
    url += `&voucher_no=${voucher_no}`;
  }

  if (output === 'excel') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};

export const get_purchase_register_report = (output, report_type, report_view, office_id, supplier_id, item_id, from_date, to_date) => {
  let url = `/report/purchase_register/?report_type=${report_type}&report_view=${report_view}&from_date=${from_date}&to_date=${to_date}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (office_id !== undefined && office_id !== '') {
    url += `&office_id=${office_id}`;
  }

  if (supplier_id !== undefined && supplier_id !== '') {
    url += `&supplier_id=${supplier_id}`;
  }

  if (item_id !== undefined && item_id !== '') {
    url += `&item_id=${item_id}`;
  }

  if (output === 'excel') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};

export const get_incentive_register_report = (output, report_type, report_view, office_id, jbl_office_id, item_id, from_date, to_date) => {
  let url = `/report/incentive_register/?report_type=${report_type}&report_view=${report_view}&from_date=${from_date}&to_date=${to_date}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (office_id !== undefined && office_id !== '') {
    url += `&office_id=${office_id}`;
  }

  if (jbl_office_id !== undefined && jbl_office_id !== '') {
    url += `&jbl_office_id=${jbl_office_id}`;
  }

  if (item_id !== undefined && item_id !== '') {
    url += `&item_id=${item_id}`;
  }

  if (output === 'excel') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};

export const get_day_close_report = (output, report_type, report_view, office_id) => {
  let url = `/report/day_close/?report_type=${report_type}&report_view=${report_view}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (office_id !== undefined && office_id !== '') {
    url += `&office_id=${office_id}`;
  }

  if (output === 'excel') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};


export const get_ibt_register_report = (output, report_type, report_view, from_office_id, to_office_id, from_date, to_date) => {
  let url = `/report/ibt_register/?report_type=${report_type}&report_view=${report_view}&from_date=${from_date}&to_date=${to_date}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (from_office_id !== undefined && from_office_id !== '') {
    url += `&from_office_id=${from_office_id}`;
  }

  if (to_office_id !== undefined && to_office_id !== '') {
    url += `&to_office_id=${to_office_id}`;
  }

  if (output === 'excel') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};

export const get_day_book_report = (output, report_type, report_view, office_id, from_date, to_date) => {
  let url = `/report/day_book/?report_type=${report_type}&report_view=${report_view}&from_date=${from_date}&to_date=${to_date}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (office_id !== undefined && office_id !== '') {
    url += `&office_id=${office_id}`;
  }

  if (output === 'excel') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};

export const get_audit_log_report = (output, report_type, report_view, office_id, staff_id, from_date, to_date) => {
  let url = `/report/audit_log/?report_type=${report_type}&report_view=${report_view}&from_date=${from_date}&to_date=${to_date}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (office_id !== undefined && office_id !== '') {
    url += `&office_id=${office_id}`;
  }

  if (staff_id !== undefined && staff_id !== '') {
    url += `&staff_id=${staff_id}`;
  }

  if (output === 'excel') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};

export const get_item_movement_register_report = (output, report_type, report_view, office_id, godown_id, item_id, from_date, to_date) => {
  let url = `/report/item_movement_register/?report_type=${report_type}&report_view=${report_view}&from_date=${from_date}&to_date=${to_date}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (office_id !== undefined && office_id !== '') {
    url += `&office_id=${office_id}`;
  }

  if (godown_id !== undefined && godown_id !== '') {
    url += `&godown_id=${godown_id}`;
  }

  if (item_id !== undefined && item_id !== '') {
    url += `&item_id=${item_id}`;
  }

  if (output === 'excel') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};

export const get_trial_balance_report2 = (output, report_type, report_view, office_id, to_date, level) => {
  let url = `/report/account/trial_balance/?report_type=${report_type}&report_view=${report_view}&to_date=${to_date}&level=${level}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (office_id !== undefined && office_id !== '') {
    url += `&office_id=${office_id}`;
  }

  if (output === 'excel') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};

export const get_trial_balance_report = (output, office_id, from_date, to_date, level, show_zero_balance, show_opening_balance) => {
  let url = `/report/account/trial_balance/?from_date=${from_date}&to_date=${to_date}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (office_id !== undefined && office_id !== '') {
    url += `&office_id=${office_id}`;
  }

  if (level !== undefined && level !== '') {
    url += `&level=${level}`;
  }

  if (show_zero_balance !== undefined && show_zero_balance !== '') {
    url += `&show_zero_balance=${show_zero_balance}`;
  }

  if (show_opening_balance !== undefined && show_opening_balance !== '') {
    url += `&show_opening_balance=${show_opening_balance}`;
  }

  if (output === 'excel') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};

export const get_profit_loss_report = (output, office_id, from_date, to_date) => {
  let url = `/report/account/profit_loss/?from_date=${from_date}&to_date=${to_date}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (office_id !== undefined && office_id !== '') {
    url += `&office_id=${office_id}`;
  }

  if (output === 'excel') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};

export const get_balance_sheet_report = (output, office_id, from_date, to_date) => {
  let url = `/report/account/balance_sheet/?from_date=${from_date}&to_date=${to_date}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (office_id !== undefined && office_id !== '') {
    url += `&office_id=${office_id}`;
  }

  if (output === 'excel') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};

export const get_purchase_vat_register_report = (output, report_type, office_id, from_date, to_date) => {
  let url = `/report/purchase_vat_register/?report_type=${report_type}&from_date=${from_date}&to_date=${to_date}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (office_id !== undefined && office_id !== '') {
    url += `&office_id=${office_id}`;
  }

  if (output === 'excel' || output === 'pdf') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};

export const get_sales_vat_register_report = (output, report_type, office_id, from_date, to_date) => {
  let url = `/report/sales_vat_register/?report_type=${report_type}&from_date=${from_date}&to_date=${to_date}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (office_id !== undefined && office_id !== '') {
    url += `&office_id=${office_id}`;
  }

  if (output === 'excel' || output === 'pdf') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};

export const get_jbl_home_appliance_demand_loan_list = (output, report_type, office_id, from_date, to_date) => {
  let url = `/third_party/jbl/home_appliance_demand_loan/list/?report_type=${report_type}&from_date=${from_date}&to_date=${to_date}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (office_id !== undefined && office_id !== '') {
    url += `&office_id=${office_id}`;
  }

  if (output === 'excel') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};

export const get_jbl_home_appliance_disbursed_loan_list = (output, report_type, office_id, from_date, to_date) => {
  let url = `/third_party/jbl/home_appliance_disbursed_loan/list/?report_type=${report_type}&from_date=${from_date}&to_date=${to_date}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (office_id !== undefined && office_id !== '') {
    url += `&office_id=${office_id}`;
  }

  if (output === 'excel') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};

export const get_client_office_list = (category_id) => {
  let url = `/third_party/jbl/office/list/?ref=web`;

  if (category_id !== undefined && category_id !== '') {
    url += `&category_id=${category_id}`;
  }

  return apiInstance.get(url);
};

export const get_ho_branch_ibt_reconciliation_report = (output, office_id, from_date, to_date) => {
  let url = `/report/ho_branch_ibt_reconciliation/?from_date=${from_date}&to_date=${to_date}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (office_id !== undefined && office_id !== '') {
    url += `&office_id=${office_id}`;
  }

  if (output === 'excel') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};

export const get_sales_materialised_list = (output, report_type, report_view, office_id, from_date, to_date) => {
  let url = `/report/sales_materialised/list/?report_type=${report_type}&report_view=${report_view}&from_date=${from_date}&to_date=${to_date}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (office_id !== undefined && office_id !== '') {
    url += `&office_id=${office_id}`;
  }

  if (output === 'excel') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};

export const get_account_main_ledger_report = (output, office_id, account_id, from_date, to_date) => {
  let url = `/report/account/main_ledger/?from_date=${from_date}&to_date=${to_date}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (office_id !== undefined && office_id !== '') {
    url += `&office_id=${office_id}`;
  }

  if (account_id !== undefined && account_id !== '') {
    url += `&account_id=${account_id}`;
  }

  if (output === 'excel') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};

export const get_account_sub_ledger_report = (output, office_id, account_id, from_date, to_date, payload) => {
  let url = `/report/account/sub_ledger/?from_date=${from_date}&to_date=${to_date}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (office_id !== undefined && office_id !== '') {
    url += `&office_id=${office_id}`;
  }

  if (account_id !== undefined && account_id !== '') {
    url += `&account_id=${account_id}`;
  }

  if (output === 'excel') {
    return apiInstance.post(url, {responseType: 'blob'});
  } else {
    return apiInstance.post(url, payload);
  }
};

export const print_stock_challan = (stock_transfer_id, output) => {
  return apiInstance.get(`/transaction/stock_transfer/pdf/?stock_transfer_id=${stock_transfer_id}&output=${output}`, {responseType: 'blob'});
};


export const get_jbl_bill_payment_report = (output, report_type, office_id, from_date, to_date) => {
  let url = `/third_party/jbl/bill_payment_report/?report_type=${report_type}&from_date=${from_date}&to_date=${to_date}`;

  if (output !== undefined && output !== '') {
    url += `&output=${output}`;
  }

  if (office_id !== undefined && office_id !== '') {
    url += `&office_id=${office_id}`;
  }

  if (output === 'excel') {
    return apiInstance.get(url, {responseType: 'blob'});
  } else {
    return apiInstance.get(url);
  }
};

export const get_party_confirmation_letter_pdf = (from_date, to_date, customer_code) => {
  let url = `/report/party_confirmation_letter/pdf/?from_date=${from_date}&to_date=${to_date}&customer_code=${customer_code}`;

  return apiInstance.get(url, {responseType: 'blob'});
};
